import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {HttpService} from "./http.service";
import { UserAuthenticationService } from './user-authentication-service';
import {Project} from '../../models/project'
import {CommonHelper} from "../helper/commonhelper";

@Injectable({
    providedIn: 'root'
})
export class BoRolesService {
    apis: Object = {};
    api_domain: string;
    api_bo: string;
    constructor(private httpService: HttpService
        , private apiService:UserAuthenticationService){

        //  this.api_domain = environment.api_domain;
this.api_domain = CommonHelper.getDomain();
        this.api_bo = environment.BO_api;
        this.apis = {
            'list': '/BORoles',
            'filter': '/BORoles/filter',
            'create': '/BORoles',
            'edit': '/BORoles',
            'delete': '/BORoles',
            'getRoleAccess': '/BORoles/RolePageRights',
            'setRoleAccess': '/BORoles/RolePageRights',
            'getUserRole': '/BORoles/UserRole',
            'setUserRole': '/BORoles/UserRole',
            'getAllUserRoles': '/BORoles/UserRole',
        };
    }


    private _getApiUrl(name):string {
        return this.api_domain + this.apis[name];
    }


    add(roleData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Put(this._getApiUrl('create'), roleData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    delete(id:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Delete(this._getApiUrl('delete')+'/'+id)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    edit(id:number,roleData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Patch(this._getApiUrl('edit')+'/'+id, roleData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }

    getAll(pageNumber:number,itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('list')+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    filter(pageNumber:number,itemsPerPage:number,filter:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('filter')+'/'+filter+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getRolePageRights(pageNumber:number,itemsPerPage:number,roleId:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getRoleAccess')+'/'+(pageNumber+1)+'/'+itemsPerPage+'/'+roleId)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    setRolePageRights(roleAccessData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Put(this._getApiUrl('setRoleAccess'), roleAccessData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    getUserRoles(pageNumber:number,itemsPerPage:number,userName:string) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getUserRole')+'/'+(pageNumber+1)+'/'+itemsPerPage+'/'+userName)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    setUserRole(userName:string, roleData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Put(this._getApiUrl('setUserRole')+'/'+userName, roleData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }

    getAllUserRoles(pageNumber:number,itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getAllUserRoles')+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }
}
