import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {HttpService} from "./http.service";
import {CommonHelper} from "../helper/commonhelper";

@Injectable({
    providedIn: 'root'
})
export class UserAuthenticationService {
    apis: Object = {};
    api_domain: string;
    constructor(private httpService: HttpService){
        this.api_domain = CommonHelper.getDomain();
        this.apis = {
            'authenticate': '/Authentication',
            'refresh-token': '/service/refresh-token',
            'logout':'/service/logout',
            'authenticate2fa':'/service/authenticate2fa',
            'renew2fa':'/service/renew2fa',
            'checkPermission': '/Authentication/CheckPermission'
        };
    }


    private _getApiUrl(name):string {
        return this.api_domain + this.apis[name];
    }



    refreshToken() {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Post(this._getApiUrl('refresh-token'), "")
                .then(
                    res => { // Success
                        if (res['code']  === 0) {
                            resolve(res);
                        } else {
                            reject(res['errorMessage']);
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+JSON.stringify(err),status:500
                })
            });
        });
        return promise;
    }

    login(username:string,password:string) {
        let promise = new Promise((resolve, reject) => {
            let loginData={
                username:username,
                password:password
            }
            console.log("username is "+username);
            this.httpService.Post(this._getApiUrl('authenticate'), loginData)
                .then(
                    res => { // Success
                        if (res['code'] === 0) {
                            resolve(res);
                        } else {
                            reject(res['errorMessage']);
                        }
                    }
                ).catch(err=>{
                    reject(
                        JSON.stringify(err)
                )
            });
        });
        return promise;
    }


    logout() {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Post(this._getApiUrl('logout'), "")
                .then(
                    res => { // Success
                        if (res['error'] === "" && res['status'] == 0) {
                            resolve(res);
                        } else {
                            reject(res)
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+JSON.stringify(err),status:500
                })
            });
        });
        return promise;
    }

    verifyOTP(username:string,otpCode:string) {
        let promise = new Promise((resolve, reject) => {
            let otpData={
                username:username,
                authCode:otpCode
            }
            this.httpService.Post(this._getApiUrl('authenticate2fa'), otpData)
                .then(
                    res => { // Success
                        if (res['error'] === "" && res['status'] == 0) {
                            resolve(res);
                        } else {
                            reject(res)
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err,status:500
                })
            });
        });
        return promise;
    }

    renewOTP(username:string)
    {
        let promise = new Promise((resolve, reject) => {
            let otpData={
                username:username
            }
            this.httpService.Post(this._getApiUrl('renew2fa'), otpData)
                .then(
                    res => { // Success
                        if (res['error'] === "" && res['status'] == 0) {
                            resolve(res);
                        } else {
                            reject(res)
                        }
                    }
                )
                .catch(err=>{
                    reject({
                        error:"Error received :"+err,status:500
                    })
                });
        });

        return promise;
    }


    checkPermission(pageUrl:string)
    {
        let promise = new Promise((resolve, reject) => {
            let accessData={
                pageUrl:pageUrl,
            }
            this.httpService.Post(this._getApiUrl('checkPermission'), accessData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                    reject({
                        error:"Error received :"+err['errorMessage'],status:err['code']
                    })
            });
        });
        return promise;
    }

}
