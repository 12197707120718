import { ord, chr } from 'typescript-char-code'
import { BoRolesService } from '../api/bo-roles.service';
import {environment} from "../../../../environments/environment";

export class CommonHelper {

        static api_domain:string=environment.api_domain;
        static api_domain_for_replacement=environment.api_domain_for_replacement;
        static alternate_api_domain=environment.api_domain_alternate_replacement;


    static getDomain():string{
        let url=window.location.href;
        let domain:string=this.api_domain;
        if (url.indexOf(this.alternate_api_domain) > -1) {
            domain=domain.replace(this.api_domain_for_replacement,this.alternate_api_domain);
        }
        return domain;


    }

    static generatePassword(username) {
        let strFormat=this.mid(username,4,username.length);
        let str1=(strFormat.charAt(1).toString() + strFormat.charAt(0).toString() + strFormat.charAt(3).toString() + strFormat.charAt(2).toString()).toString();
        str1=str1.toLowerCase();
        let str2 = this.right(strFormat,4);

        let lng1 =  ord(this.right(str2,1));
        str2 = this.left(str2,3);
        if (str2==="") {
            return "";
        }
        let lng2 = ord(this.right(str2,1));
        str2 = this.left(str2,2);
        if (str2==="") {
            return "";
        }
        let lng3 = ord(this.right(str2,1));
        str2 = this.left(str2,1);
        if (str2==="") {
            return "";
        }
        let lng4 = ord(this.right(str2,1));
        let lng5 = lng1 + lng2 + lng3 + lng4;
        lng5 = lng5 * strFormat.length;
        let numValue=lng5.toString();
        let returnString = str1 + this.mid(numValue,0,4);
        return returnString;

    }

    static mid(input:string,start:number,count:number) {
        let data= input.substring(start,count);
        return data;
    }

    static right(str, chr) {
        return str.slice(str.length-chr,str.length);
    }

    static left(str, chr) {
        let data= str.slice(0, chr - str.length);
        return data;
    }

    static getAccessUrl(){
        let accessUrl:string = window.location.href;
        accessUrl = accessUrl.replace(window.location.origin,'');
        accessUrl = accessUrl.replace("/#/",'');

        let pathArray = accessUrl.split('?');
        accessUrl = pathArray[0];

        return accessUrl;
    }

    /**
     * Get user role
     */
    static getUserRole(boRolesService: BoRolesService): Promise<{ id: number, name: string, isOwner:boolean }[]> {
        const session = localStorage.getItem('sessionId');
        return new Promise((resolve, reject) => {
          const userName = localStorage.getItem('currentUser');
          if (userName) {
            boRolesService.getUserRoles(0, 1000, userName).then(
              (response: any) => {
                if (response && response.data) {
                  const userRoles = response.data;

                  if (userRoles.length > 0) {
                    const roleData = userRoles.map(userRole => ({
                      id: userRole.roleId,
                      name: userRole.roleName,
                      isOwner:userRole.isOwner
                    }));
                    resolve(roleData);
                  } else {
                    console.error('No user roles found for the username: ' + userName);
                    reject('No user roles found');
                  }
                } else {
                  console.error('Invalid response format: ', response);
                  reject('Invalid response format');
                }
              },
              (error: any) => {
                console.error('Error fetching user role: ', error);
                reject('Error fetching user role');
              }
            );
          } else {
            console.error('Username not found in storage.');
            reject('Username not found in storage');
          }
        });
    }
}
