import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {HttpService} from "./http.service";
import { HttpClient } from '@angular/common/http';
import { UserAuthenticationService } from './user-authentication-service';
import {Surveyor} from '../../models/surveyor'
import {CommonHelper} from "../helper/commonhelper";

@Injectable({
    providedIn: 'root'
})
export class SurveyorService {
    apis: Object = {};
    api_domain: string;
    api_bo: string;
    constructor(private httpService: HttpService
        ,private apiService:UserAuthenticationService
        ,private http: HttpClient){

        //  this.api_domain = environment.api_domain;
this.api_domain = CommonHelper.getDomain();
        this.api_bo = environment.BO_api;
        this.apis = {
            'listSurveyors': '/Surveyor',
            'filterSurveyors':'/Surveyor/filter',
            'createSurveyor': '/Surveyor/Surveyor',
            'editSurveyor': '/Surveyor/Surveyor',
            'deleteSurveyor': '/Surveyor/Surveyor',
            'updateUserProjects':'/Surveyor/UpdateUserProjects',
            'getSurveyorInfo': '/Surveyor',
            'getSurveyorProjects':'/Projects/UserProject',
            'activeSurveyors': '/Surveyor/activesurveyors',
            'getSurveyorsByProjectName': '/Surveyor/byProjectName',
            'surveyorOwners': '/Surveyor/SurveyorOwners',
            'updateProfileImage': '/Surveyor/SurveyorProfileImage',
            'getProfileImage': '/Surveyor/SurveyorProfileImage',
            'listSurveyorDocuments':'/Surveyor/SurveyorDocument',
            'uploadSurveyorDocument':'/Surveyor/SurveyorDocument/Upload',
            'downloadSurveyorDocument':'/Surveyor/SurveyorDocument/Download',
            'deleteSurveyorDocument':'/Surveyor/SurveyorDocument',
        };
    }


    private _getApiUrl(name):string {
        return this.api_domain + this.apis[name];
    }


    add(projectData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Put(this._getApiUrl('createSurveyor'), projectData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    delete(id:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Delete(this._getApiUrl('deleteSurveyor')+'/'+id)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    edit(id:any,surveyorData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Patch(this._getApiUrl('editSurveyor')+'/'+id, surveyorData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    updateUserProjects(surveyor:any,listProjectData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Post(this._getApiUrl('updateUserProjects')+'/'+surveyor, listProjectData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    getUserProjects(username:string,pageNumber:number,itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getSurveyorProjects')+'/'+(pageNumber+1)+'/'+itemsPerPage+'/'+username)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getAllUserProjects(pageNumber:number,itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getSurveyorProjects')+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getAll(pageNumber:number,itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('listSurveyors')+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }


    filter(pageNumber:number,itemsPerPage:number,filter:any, projectIds:any, activeState:number, owner:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('filterSurveyors')+'/'+(pageNumber+1)+'/'+itemsPerPage+'/'+filter+'/'+encodeURIComponent(projectIds)+'/'+activeState+'/'+encodeURIComponent(owner))
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getSurveyorInfo(surveyor) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getSurveyorInfo')+'/'+surveyor)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getActiveSurveyors(pageNumber: number, itemsPerPage: number) {
        return new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('activeSurveyors') + `/${pageNumber + 1}/${itemsPerPage}`)
                .then(
                    res => {
                        resolve(res);
                    }
                )
                .catch(err => {
                    reject({
                        error: "Error received :" + err['errorMessage'], status: err['code']
                    });
                });
        });
    }

    getSurveyorsByProjectName(pageNumber: number, itemsPerPage: number, projectName: any) {
        return new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getSurveyorsByProjectName') + `/${pageNumber + 1}/${itemsPerPage}/${projectName}`)
                .then(
                    res => {
                        resolve(res);
                    }
                )
                .catch(err => {
                    reject({
                        error: "Error received :" + err['errorMessage'], status: err['code']
                    });
                });
        });
    }

    getSurveyorOwners(pageNumber: number, itemsPerPage: number) {
        return new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('surveyorOwners') + `/${pageNumber + 1}/${itemsPerPage}`)
                .then(
                    res => {
                        resolve(res);
                    }
                )
                .catch(err => {
                    reject({
                        error: "Error received :" + err['errorMessage'], status: err['code']
                    });
                });
        });
    }

    getSurveyorDefaultProfile() {
        return this.http.get<any>('assets/backoffice/data/surveyor-default-profile.json')
            .toPromise()
            .then(res => res.data as any[])
            .then(data => data);
    }

    addProfileImage(data:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Put(this._getApiUrl('updateProfileImage'), data)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    editProfileImage(data:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Patch(this._getApiUrl('updateProfileImage'), data)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }

    getProfileImage(surveyor: any) {
        return new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getProfileImage') + `/${surveyor}`)
                .then(
                    res => {
                        resolve(res);
                    }
                )
                .catch(err => {
                    reject({
                        error: "Error received :" + err['errorMessage'], status: err['code']
                    });
                });
        });
    }

    getSurveyorDocuments(surveyor:any, showDeleted:boolean, pageNumber:number,itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('listSurveyorDocuments')+'/'+(surveyor)+'/'+(showDeleted)+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    uploadSurveyorDocument(surveyor: any, data:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Post(this._getApiUrl('uploadSurveyorDocument')+'/'+(surveyor), data)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }

    downloadSurveyorDocument(id:number) {
        return this.http.get(this._getApiUrl('downloadSurveyorDocument')+'/'+(id), { responseType: 'blob' });
    }

    deleteSurveyorDocument(id:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Delete(this._getApiUrl('deleteSurveyorDocument')+'/'+id)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }
}
