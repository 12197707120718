import { Component, OnInit } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { BoUserInfo } from 'src/app/backoffice/models/bo-user-info';
import {BoUsersService} from "src/app/backoffice/service/api/bo-users-service";
import {SurveyorService} from "src/app/backoffice/service/api/surveyor.service";
import { StorageService } from 'src/app/backoffice/service/storage.service';
import { AuthService } from 'src/app/backoffice/service/auth/auth.service';
import { Router } from "@angular/router"

@Component({
    selector: 'user-profile',
    templateUrl: './user.profile.component.html',
    styles: [`
        :host ::ng-deep  .p-frozen-column {
            font-weight: bold;
        }

        :host ::ng-deep .p-datatable-frozen-tbody {
            font-weight: bold;
        }

        :host ::ng-deep .p-progressbar {
            height:.5rem;
        }
        :host ::ng-deep .p-message {
            margin-left: .25em;
        }

        :host ::ng-deep .p-toast{
            z-index:99999;
        }

        a { text-decoration: none; }
    `]
})
export class UserProfileComponent  implements OnInit {

    userInfo:any;
    
    constructor(private router: Router, private storageService:StorageService, 
        private authService:AuthService, private boUsersService: BoUsersService, 
        private surveyorService: SurveyorService){}

    ngOnInit(): void {
        this.getUserInfo();        
    }

    getUserInfo(){
        if(this.authService.isAuthenticated()) {
            let currentUser=this.storageService.getUser();
            let isSurveyor=this.storageService.getIsSurveyor();
            if(isSurveyor) {
                this.surveyorService.getSurveyorInfo(currentUser).then(res => {
                    let data:any=res;
                    let surveyorInfo = data.data;
                    this.userInfo = {
                        email:surveyorInfo.email,
                        firstName:surveyorInfo.surveyorName,
                        userInitials:surveyorInfo.surveyorName.substring(0,2)
                    };
                }).catch(err => {
    
                });
            } else {
                this.boUsersService.getUserInfo(currentUser).then(res => {

                    let data:any=res;
                    this.userInfo = data.data;
                }).catch(err => {
    
                });
            }
            
        }
    }

    onSignout(){
        this.storageService.setSessionId("");
        this.router.navigate(["/auth/login"]);
    }
}

