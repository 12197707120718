export const environment = {
    production: true,
    api_domain: "https://boapi.reach.co.nz/boapi/v1",
    api_domain_for_replacement:"reach.co.nz",
    api_domain_alternate_replacement:"ipsos.co.nz",
    BO_api: "/boapi/v1",
    socketio_url: "https://boapi.reach.co.nz",
    sessionTimeoutInSeconds: 5400

};
